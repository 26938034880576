@import "./stanford.scss";
@import "./karel.scss";
@import "./tiptap.scss";
@import "./landing.scss";
@import "./formComponents.scss";
@import "./applications.scss";
@import "./ide.scss";
@import "./terminalStyle.scss";
@import "./course.scss";
@import "./textbook.scss";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");

#root {
  height: 100%;
  overflow: auto;
}

body {
  font-family: 'Nunito', Helvetica, sans-serif;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overscroll-behavior-x: none
}

.stanford-serif-font {
  font-family: Stanford, 'Source Serif Pro', Georgia, Times, 'Times New Roman', serif;
}

.stanford-sans-font {
  font-family: Stanford, 'Source Sans Pro', Helvetica, Arial, sans-serif;
}

code {
  color: black;
  padding: 0px;
  border: 0px;
}

mark {
  padding: 0 !important;
}

.sendthanks-extra-yes-bt {
  background-color: #3cbc71;
  color: #fff
}

.sendthanks-extra-yes-bt:hover {
  background-color: #30985b;
  color: #fff
}

.sendthanks-yes-bt {
  background-color: #3c8dbc;
  color: #fff
}

.sendthanks-yes-bt:hover {
  background-color: #266d96;
  color: #fff
}

.sendthanks-no-btn {
  background-color: #aaaaaa;
  color: #fff
}

.sendthanks-no-btn:hover {
  background-color: #888888;
  color: #fff
}

.gotohome-btn-purple-outline:hover {
  color: #fff;
  background-color: #b757f6;
  border-color: #b757f6;
}

.metaGrading-thumbButtonContainer {
  display: flex;
  padding: 12px 0 12px 0;
  justify-content: center;
}

.metaGrading-thumbButton {
  /* color: white;
  background-color: #0069d9;
  border: solid 1px #0062cc; */
  border: none;
  background-color: aliceblue;
  margin: 0 8px 0 8px;
  padding: 12px;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
}

.metaGrading-thumbButtonDeselected {
  background-color: transparent;
}


.landingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disabled-link{
  pointer-events: none;
  cursor: not-allowed !important;
  color: #999;
}

.blue {
  color: #346df1;
  font-weight: bold;
}

.sortable {
  cursor: pointer;
}

.coursenameOld {
  color: white;
  font-size: 24px;
  line-height: 24px;
  font-family: "Source Serif Pro", serif;
  font-weight: 450;
}

.toast-container {
  z-index: 9999999 !important;
  background-color: white !important;
}

.coursename {
      // font-family: Stanford, 'Source Serif Pro', Georgia, Times, 'Times New Roman', serif;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      text-decoration: none;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      letter-spacing: 0;
      -webkit-font-feature-settings: "liga";
      -ms-font-feature-settings: "liga" 1;
      font-feature-settings: "liga";
      -webkit-font-variant-ligatures: discretionary-ligatures;
      font-variant-ligatures: discretionary-ligatures;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: white;
      font-size: 22px;
      line-height: 24px;
}

a {
  font-weight:600;
  color:#337ab7
}


.react-tabs__tab-list {
  margin: 0px !important;
}

.ide-side-bar {
  background-color: #cbc3e3;
}

.ide-nav-selected {
  padding: 12px 16px 12px 11px;
  border-left: 10px solid #316cf4;
  color: black;
}

.ide-nav-selected:hover {
  border-left: 10px solid #316cf4;
}

.font-12 {
  font-size: 12px;
}

.ace_gutter {
  background-color: rgba(1.0,1.0,1.0,0.02) !important;
}

.ide-nav-unselected {
  padding: 12px 16px 12px 11px;
  border-left: 10px solid #cbc3e3;
  color: rgb(70, 70, 70);
}

.ide-error-select {
  color: #999;
}

.ide-error-select button {
  color: #999;
}

.ide-left-col-outer {
  background-color: lavender;
}

.marker-yellow {
  background: lavender;
  position: absolute;
}

.marker-red {
  background: #f8d7da;
  position: absolute;
}

.ace_print-margin {
  visibility: hidden !important;
}
$cardinal-alt: #a2221c;
$cardinal-red: #8c1515;
$cardinal-light: #b83a4b;
$light-sky: #4298b5;
$dark-sky: #016895;
$sky: #4298b5;
$plum: #734675;
$archway: #5d4b3c;
$archway-light: #766253;
$spirited: #e04f39;
$plum-dark: #350d36;
$brick-dark: #42081b;
$olive: #8f993e;
$palo-verde: #279989;

$vscode-blue: #3478c6;

$splash-margin: 10px;

// $sidebar-bg-color: #1d1d1d !default;
// $sidebar-color: #adadad !default;
// $sidebar-width: 270px !default;
// $sidebar-collapsed-width: 80px !default;
// $highlight-color: #d8d8d8 !default;
// $submenu-bg-color: #2b2b2b !default;
// $submenu-bg-color-collapsed: #2b2b2b !default;
// $icon-bg-color: #2b2b2b !default;
// $icon-size: 35px !default;
// $submenu-indent: 24px !default;

.markdownView {
  min-width: 200px;
  text-align: justify;
  overflow: scroll;

  img {
    width: 100%;
  }
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}

.runnable-code {
  position: relative;

  .content {
    margin-top: 1.5rem;
    padding: 1rem;
  }
}



.paper {
  position: relative;

  .content {
    margin-top: 1.5rem;
    padding: 1rem;
  }
}

.paperCanvas {
  background: #f8f8fa;
  cursor: crosshair;
  border-radius: 5px;

  path {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.react-component-with-content {
  background: #faf594;
  border: 3px solid #0d0d0d;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;

  .label {
    margin-left: 1rem;
    background-color: #0d0d0d;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
  }

  .content {
    margin: 2.5rem 1rem 1rem;
    padding: 0.5rem;
    border: 2px dashed #0d0d0d20;
    border-radius: 0.5rem;
  }
}

$sidebar-bg-color: black; //002154
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
//$icon-bg-color: #2D2C2C;
$icon-bg-color: black;
$icon-size: 40px;
$sidebar-width: 155px !default;
$sidebar-collapsed-width: 55px !default;


// .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item

.splashNavbar {
  background-color: $archway !important;
}

.purpleBox {
  padding: 10px;
  background-color: #eff1fc;
  border-radius: 0.25rem;
  overflow-y: auto;
  text-align: justify;

  img {
    width: 100%;
  }
}

.fullSizeImg {
  width: 100%;
}

.fullSizeProfile {
  width: 100%;
  border-radius: 7px;
  object-fit: cover;
}

// Make the default scroll for divs in windows look just like mac
// https://gist.github.com/spemer/a0e218bbb45433bd611e68446523a00b

/* total width */
::-webkit-scrollbar {
  // background-color: #fff;
  width: 8px;
  height:8px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  // background-color: #fff;
  width: 4px;
  height:4px;
  padding: 0;
  margin: 0;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 4px;
  border: 0px solid #fff;
}


.stanford-primary {
  background-color: $archway-light;
  border-color: black;
  color: black;
}

.pro-icon {
  animation: none !important;
  font-size: 24px;
}

.pro-menu-item.active {
  .pro-icon {
    background-color: white !important;
    border-radius: 20px;
    color: black;
  }
}

.pro-menu-item .pro-inner-item {
  // default is...
  // 8, 35, 8, 20
  padding: 5px 15px 5px 8px !important;
}

.table-no-left-padding {
  margin-left: -0.5rem;
}

.app {
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  .content {
    overflow-y: auto;
    flex-grow: 4;
    height: 100vh;
  }
}

.asideOuter {
  height: calc(100% - 0px);
  width: 100%;
  display: flex;
  position: relative;

  .content {
    overflow-y: auto;
    flex-grow: 4;
    height: 100vh;
  }

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #f3f3f3;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }

}

/** Necessary to make the split panes scrollable! **/

.SplitPane {
  position: relative !important;
  min-height: 0px !important;
}

.Pane1 {
  overflow: auto;
}

.Pane2 {
  overflow: auto;
}

.ide {
  width: 100%;
  height: 100%;
}

/** For the IDE **/
.codeTab {
  padding: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 2;
  border-right: 1px solid #eee;
  overflow: auto;
  padding-top: 0px;
}

.tab-pane {
  height: 100%;
}

.tab-content {
  height: 100%;
}

.ideOutput {
  flex-grow: 2;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Resizer {
  opacity: 1;
  z-index: 1000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  background-color: #000;
}

.gutter {
  z-index: 99;
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
  width: 5px !important;
}

.gutter.gutter-vertical {
  cursor: row-resize;
  height: 5px !important;
}

.gutter:hover {
  border-top: 2px solid #3f8eea;
  border-bottom: 2px solid #3f8eea;
  background: #3f8eea;
}

.profile-editor-field-input {
  max-width: 300px;
}

.ideOuter {
  flex-grow: 2;
  overflow: hidden;
  background-color: #eee;
}

.pane {
  background-color: white;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.Resizer:hover {
  -webkit-transition: all 0s ease;
  transition: all 0s ease;
}

.Resizer.horizontal {
  height: 5px;
  margin: -2px 0;
  border-top: 2px solid rgba(255, 255, 255, 0);
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 2px solid #3f8eea;
  border-bottom: 2px solid #3f8eea;
  background: #3f8eea;
}

.Resizer.vertical {
  width: 5px;
  margin: 0 -2px;
  border-left: 2px solid rgba(255, 255, 255, 0);
  border-right: 2px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 2px solid #3f8eea;
  border-right: 2px solid #3f8eea;
  background: #3f8eea;
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

/** For the course **/

a {
  color: #337ab7;
  text-decoration: none;
}

.orientationRow {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.splashAsideContainer {
  width: max(100%, 100vw);
  height: max(100%, 100vh);
  display: flex;
  justify-content: space-between;
}







.splashNavbarContainer {
  width: 100vw;
  height: 100vh;
}

.codeSplashContainer {
  width: 1130px;
}

.mainNavButton {
  width: 25px;
  height: 25px;
  color: lightgray;
  cursor: pointer;
}

.care-hours-button:hover {
  background-color: rgb(36, 139, 79);
  color: white;
  transition: background-color 0.3s, color 0.3s;
  font-size: large;
}

.care-hours-button {
  background-color: rgb(58, 208, 121);
  border: none;
  color: #fff;
  text-decoration: none;
  font-size: large;
}

.care-hours-button:active {
  background-color: rgb(36, 139, 79);
  color: white;
  font-size: large;
}

.karmaNotificationDot {
  color: white;
  background-color: rgb(26, 171, 53);
  border-radius: 100%;
  position: absolute;
  display: flex;
  width: 15px;
  height: 15px;
  bottom: 9%;
  text-align: center;
  right: 27%;
  font-size: 10px;
  font-weight: bolder;
  font-family: "Roboto", sans-serif;
  align-items: center;
  justify-content: center;
}

.karmaPlusOne {
  display: inline-block;
  width: 35px;
  color: white;
  padding: 1px;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  // margin-left: 50px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
}


.splashAside {
  z-index: 100;
  // Similar to Canvas
  overflow-y: scroll;
  padding-left: 15px;
}

.learningAsideContainer {
  display: flex;
  width: 100%;
}

.learnAside {
  background-color: #7386d5;
  color: white;
  width: 250px;
  padding-left: 10px;
  padding-right: 10px;
}

.ideTitle {
  font-size: 1.2em;
}

.ideCodeTab {
}

.ideTabCode {
  font-size: 1.2em;
  font-family: sans-serif;
  padding-bottom: 7px;
  font-weight: 500;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: white;
  display:flex;
  width:100%;

  // mobile
  @media (max-width: 767.98px) {
    justify-content: space-between;
    padding-right: 0px;
    padding-top:5px;
    padding-bottom:1px;
  }
}

.ideTabLeftMargin {
  background-color: white;
  width: 0px;
}

.ideTabButtonBar {
  background-color: white;
  padding-top: 7px;
  padding-right: 5px;
}

.ideTabFiller {
  flex-grow: 2;
  background-color: white;
}

.cursor-label {
  color: #fff;
  padding: 2px;
  font-size: 8pt;
  position: absolute;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 3px 3px 3px 0;
  // color: #0d0d0d;
  // font-size: 12px;
  // font-style: normal;
  // font-weight: 600;
  // -webkit-user-select: none;
  // user-select: none;
  // white-space: nowrap;
  margin-top: 3px;
  margin-left: -2px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
}

.cursor-label {
  color: #fff;
  padding: 2px;
  font-size: 8pt;
  position: absolute;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 3px 3px 3px 0;
  // color: #0d0d0d;
  // font-size: 12px;
  // font-style: normal;
  // font-weight: 600;
  // -webkit-user-select: none;
  // user-select: none;
  // white-space: nowrap;
  margin-top: 3px;
  margin-left: -2px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
}

.cursor {
  pointer-events: none;
}

.ideHeadingDark {
  font-size: 1.2em;
  color: white;
  font-family: sans-serif;
  padding-bottom: 0px;
  // border-bottom: 1px solid white;
  font-weight: 700;
}

.ideHeadingLight {
  font-size: 1.2em;
  font-family: sans-serif;
  padding-bottom: 7px;
  font-weight: 700;
  color: #5d44c9;
  width: 100%;
}

.learnAside > ul > li > .nav-link {
  color: white !important;
  padding: 5px;
  font-size: 1.2em;
  display: block;
  line-height: 1.5;
}

.learnContentConatiner {
  width: 700px;
  height: 100vh;
  overflow: auto;
  flex: 2;
}

.learnCenteredContent {
  display: flex;
  align-items: center;
  width: 700px;
  height: 100vh;
  flex-direction: column;
  overflow: auto;
  flex: 2;
}

.learnCourseRow {
  display: flex;
  flex-direction: row;
}

.mt-2 {
  margin-top: 10px;
}

.mt-4 {
  margin-top: 20px;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}
.mr-3 {
  margin-right: 15px;
}

.mr-4 {
  margin-right: 20px;
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}
.ml-3 {
  margin-left: 15px;
}

.ml-4 {
  margin-left: 20px;
}

.pl-1 {
  padding-left: 5px;
}

.pl-2 {
  padding-left: 10px;
}

.pl-3 {
  padding-left: 15px;
}

.pl-4 {
  padding-left: 20px;
}

.pr-1 {
  padding-right: 5px;
}

.pr-2 {
  padding-right: 10px;
}

.pr-3 {
  padding-right: 15px;
}

.pr-4 {
  padding-right: 20px;
}


.w-700 {
  width: 700px;
}

.splashBody {
  flex-grow: 2;
  display: flex;
  align-items: space-between;
  flex-direction: column;
  overflow-y: scroll;
  height: 100vh;
  /* padding-top: 20px; */
}

.splashContainer {
  display: flex;
  justify-content: space-between;
}

.courseContentContainer {
  // padding:10px;
  width: 100%;
  overflow-y: auto;
}

.logoImg {
  width: 80px;
  margin-right: 5px;
}

.splashTitleCard {
  display: flex;
  margin-top: 10px;
}



.splashMainColContainer {
  flex-grow: 2;
  display: flex;
  justify-content: center;
}

// discrepancy between max-width 650 and 750px
.splashMainCol {
  max-width: 750px;
  min-width: 750px;
  margin-left: $splash-margin;
}

.splashRightCol {
  width: 200px;
  min-width: 200px;
  margin-right: $splash-margin;
  margin-left: 20px;
  margin-top: 0px;
}

.splashCard {
  background-color: white;
  padding: 10px;
  border-radius: 1px;
  margin-top: $splash-margin;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.splashButtonTitle {
  background-color: white;
  padding: 10px;

  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.courseTitle {
  font-size: 32px;
  font-weight: bolder;
}

.splashTitle {
  font-size: 32px;
  font-weight: bolder;
}

.splashButtonLocked {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: grey;
}

/* TODO: Can this class be removed? */
.splashButton {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 180px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lessonButton {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.lessonButton:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

/* TODO: Can this class be removed? */
.splashButtonImg {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.splashButtonIcon {
  width: 80px;
  height: 80px;
}

/* TODO: Can this class be removed? */
/* On mouse-over, add a deeper shadow */
.splashButton:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
}


.activeSidebarButton{
  background-color:#eff1fc;
}

.activeSidebarButton:hover{
  background-color:#eff1fc;
}

.sidebarButton:hover{
  background-color: rgb(245, 247, 248);
}

.sidebarButton{
  background-color: #ffffff;
}

.greenCheck {
  font-size: x-large;
  color: green;
  margin-top: 0px;
}


// .subtleHeading {
// font-size: 14px;
// font-weight: 700;
// color: #959595;
// text-transform: uppercase;
// letter-spacing: 1px;
// margin-bottom: 5px;
// }


.splashCardMainTitle {
  background-color: white;
  color: black;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ddd;
  font-size: large;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  margin-bottom: 5px;
  padding-bottom: 2px;
  width: 100%;
}

.asideLink {
  padding-top: 12px;
  padding-bottom: 12px;
}

.splashCardRightTitle {
  background-color: white;
  color: #6d6d6d;
  font-size: medium;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  width: 100%;
}

.projectIcon {
  width: 150px;
  height: 100px;
}

.profileCard {
  display: flex;
  justify-content: space-between;
}

.profileName {
  font-weight: 900;
  font-size: large;
}

.profileCardInfo {
  width: 170px;
}

.splashProfileImg {
  width: 99px;
  height: 99px;
  object-fit: cover;
}

.profileEditImg {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.draftEditorWrapper {
  // flex-grow: 2;
  overflow-y: auto;
  height: auto;
}

.draftEditorWrapper img {
  max-width: 100%;
}

.katex {
  font-size: 1.1em;
}

.draftEditorContentWrapper {
  // padding-left:5px;
  overflow: auto;
  height: auto;
  max-width: 670px;
  // font-family: "Times New Roman", Times, serif;;
  // font-size:19px;
}

.public-DraftStyleDefault-ltr {
  text-align: justify;
}

.backgroundVideo {
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

/* Add some content at the bottom of the video/page */
.walkOverlayButton {
  position: fixed;
  bottom: 0;
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: right;
  right: 20px;
}

.chatBubbleContainer {
  display: flex;
  flex-direction: column;
}

.chatBubbleSenderLabel {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.8rem;
  padding-left: 12px;
}

.chatBubble {
  border-radius: 20px;
  max-width: 70%;
  min-width: 30%;
  margin-bottom: 4px; // add breathing room between bubbles
  overflow-wrap: break-word; // handles line break for very long words
  padding: 4px 10px 3px 10px;
}

.chatBubbleUser {
  align-self: end;
  background-color: lightskyblue;
}

.chatBubbleOther {
  align-self: start;
  background-color: #f1f1f1;
}

.assignmentsPath {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 40%;
  position: relative;
}

.assignmentsPathNodeContainer {
  align-items: center;
  display: flex;
  margin-top: 8px;
  position: relative;
}

.assignmentsPathNode {
  align-items: center;
  border-radius: 50%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 64px;
  justify-content: center;
  width: 64px;
}

$assignments-node-completed: rgb(0, 255, 100, 0.6);
$assignments-node-locked: rgba(0, 0, 0, 0.2);
$assignments-node-unlocked: rgb(0, 215, 255, 0.8);

.assignmentsPathNodeLocked {
  background-color: $assignments-node-locked;
}

.assignmentsPathNodeCompleted {
  background-color: $assignments-node-completed;
}

.assignmentsPathNodeUnlocked {
  background-color: $assignments-node-unlocked;
}

.assignmentsPathNodeIcon {
}

.assignmentsPathNodeIconLocked {
  color: black; // TODO: change
}

.assignmentsPathNodeIconCompleted {
  color: darkgreen;
}

.assignmentsPathNodeIconUnlocked {
  color: darkblue;
}

.assignmentsPathNodeContainer:nth-child(1),
.assignmentsPathNodeContainer:nth-child(5) {
  left: 0px;
}

.assignmentsPathNodeContainer:nth-child(2),
.assignmentsPathNodeContainer:nth-child(4) {
  left: -24px;
}

.assignmentsPathNodeContainer:nth-child(3) {
  left: -36px;
}

.assignmentsPathNodeContainer:nth-child(6),
.assignmentsPathNodeContainer:nth-child(8) {
  left: 24px;
}

.assignmentsPathNodeContainer:nth-child(7) {
  left: 36px;
}

.assignmentsPathNodeLabel {
  margin-left: 8px;
}

.assignmentsPathNextMarker {
  padding: 0.5rem 0.5rem;
}

.assignmentsUnitHeader {
  align-items: center;
  background-color: lightsteelblue;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 8px 16px 8px 16px;
  box-shadow: rgba(0, 0, 0, 0.2);
}

.assignmentsUnitHeaderTitle {
  font-size: 24px;
  margin-bottom: 4px;
  padding-top: 4px;
}

.assignmentsUnitHeaderLocked {
  background-color: $assignments-node-locked;
}

.assignmentsUnitHeaderCompleted {
  background-color: $assignments-node-completed;
}

.assignmentsUnitHeaderUnlocked {
  background-color: $assignments-node-unlocked;
}

.tree__folder {
  padding: 0 !important;
}

.tree__file {
  &:hover {
    background: #f3f3f3 !important;
  }
}

.ais-SearchBox {
  padding: 0px !important;
}


/* Style for the body of a handout page */
.greyBackground{
  background-color: #535659
}
/* When the handout drops bellow a threshold remove padding */
@media (max-width: 1000px) {
  .greyBackground {
    background-color: white;
  }

  .handout {
    /* Note: since padding drops from 100 to 15, max-width
       also drops. 760 = 930 - (100-15)*2 */
    // max-width: 760px;
    padding-left:15px;
    padding-right:15px;
    margin-top:0px;
    padding-top:30px;
  }
}